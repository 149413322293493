import { createBrowserRouter, Navigate, RouterProvider } from 'react-router';
import HomePage from './home/HomePage';
import LoginPage from './login/LoginPage';

const router = createBrowserRouter([
  {
    index: true,
    element: <Navigate to="/home" replace={true} />,
  },
  {
    path: '/home',
    index: true,
    element: <HomePage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
