import axios, { AxiosError, HttpStatusCode } from 'axios';
import { useAuthStore } from '../../auth/AuthStore';
import {
  Configuration,
  ProvisioningApiFactory,
  ProvisioningLotsApiFactory,
} from './client';

const httpClient = axios.create({
  timeout: 5000,
});

httpClient.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error.status === HttpStatusCode.Unauthorized) {
    const authStore = useAuthStore.getState();
    if (authStore.token) {
      console.log(
        `${HttpStatusCode.Unauthorized} response received, perform logout.`,
      );
      authStore.setToken(undefined);
    }
  }

  return Promise.reject(error);
});

const provisioningApiConfig = new Configuration({
  basePath: process.env.REACT_APP_IOT_CORE_API_BASE_URL,
  accessToken: () => {
    return useAuthStore.getState().token ?? '';
  },
});

export const provisioningLotsApi = ProvisioningLotsApiFactory(
  provisioningApiConfig,
  undefined,
  httpClient,
);

export const provisioningApi = ProvisioningApiFactory(
  provisioningApiConfig,
  undefined,
  httpClient,
);
