import { useAuthStore } from '../common/auth/AuthStore';
import { Navigate } from 'react-router';
import React, { FormEvent, useState } from 'react';
import { useLoginFormState, useLoginFormStateReset } from './LoginFormState';
import { useShallow } from 'zustand/react/shallow';

import iconVisibilityOn from '../assets/icons/visibility_on.svg';
import iconVisibilityOff from '../assets/icons/visibility_off.svg';
import iconQrCodeScanner from '../assets/icons/qr_code_scanner.svg';
import touwiLogo from '../assets/images/touwi.svg';

import { TwCard } from '../common/TwCard';
import { TwButton } from '../common/TwButton';
import { TwInputLabel } from '../common/TwInputLabel';
import { TwInput } from '../common/TwInput';
import { TwInputWithLabel } from '../common/TwInputWithLabel';
import { Modal } from '../common/Modal';
import { Scanner } from '@yudiel/react-qr-scanner';

export default function LoginPage() {
  const authStore = useAuthStore();
  const hasToken = !!authStore.token;

  if (hasToken) {
    return <Navigate to="/home" replace={true} />;
  }

  return <LoginCard />;
}

function LoginCard() {
  useLoginFormStateReset();
  const submit = useLoginFormState((state) => state.submit);
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    submit();
  };

  return (
    <div className="flex h-svh w-screen items-center justify-center bg-gray-200">
      <TwCard className="m-5 flex w-full max-w-2xl">
        <form className="flex w-full flex-col gap-5" onSubmit={onSubmit}>
          <Title />
          <TokenInput />
          <LoginButton />
          <QrCodeButton />
          <ErrorMessage />
        </form>
      </TwCard>
    </div>
  );
}

function Title() {
  return (
    <div className="flex flex-col items-center gap-5 text-center text-2xl font-semibold text-gray-700">
      <img className="w-[200px]" src={touwiLogo} alt="" />
      <div>Post-production tools</div>
    </div>
  );
}

function TokenInput() {
  const [token, setToken, isSubmitting] = useLoginFormState(
    useShallow((state) => [state.token, state.setToken, state.isSubmitting]),
  );
  const [showToken, setShowToken] = useState(false);

  return (
    <TwInputWithLabel
      className="w-full"
      label={<TwInputLabel htmlFor="token">Auth token</TwInputLabel>}
      input={
        <TwInput
          type={showToken ? 'text' : 'password'}
          inputId="token"
          value={token}
          onValue={setToken}
          disabled={isSubmitting}
          placeholder="Auth token"
          required
          trailing={
            <img
              className="h-full cursor-pointer"
              src={showToken ? iconVisibilityOn : iconVisibilityOff}
              alt="Toggle token visibility"
              onClick={() => setShowToken((showToken) => !showToken)}
            />
          }
        />
      }
    />
  );
}

function QrCodeButton() {
  const [showModal, setShowModal] = useState(false);

  const onClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <button
        type="button"
        className="mx-auto flex w-fit items-center gap-2 rounded p-2 outline-indigo-600 hover:bg-gray-100"
        onClick={onClick}
      >
        <div>Scan QR code</div>
        <img
          className="h-[24px]"
          src={iconQrCodeScanner}
          alt={'Scan QR code to login'}
        />
      </button>
      {showModal && (
        <QrCodeScannerModal onDismissRequest={() => setShowModal(false)} />
      )}
    </>
  );
}

function LoginButton() {
  const [hasInput, isSubmitting] = useLoginFormState(
    useShallow((state) => [state.token.trim().length > 0, state.isSubmitting]),
  );
  return (
    <TwButton
      className="h-10 w-full"
      type="submit"
      disabled={isSubmitting || !hasInput}
    >
      {isSubmitting ? 'Please wait...' : 'Login'}
    </TwButton>
  );
}

function ErrorMessage() {
  const errorMessage = useLoginFormState((state) => state.submitError);

  return (
    <div
      className={`w-full text-center text-red-500 ${errorMessage ? '' : 'invisible'}`}
    >
      {errorMessage ?? 'error'}
    </div>
  );
}

function QrCodeScannerModal(props: { onDismissRequest?: () => void }) {
  const [setToken, submit] = useLoginFormState(
    useShallow((state) => [state.setToken, state.submit]),
  );

  return (
    <Modal
      onDismissRequest={props.onDismissRequest}
      className="flex items-center justify-center"
    >
      <div className="m-4 max-w-lg" onClick={(e) => e.stopPropagation()}>
        <Scanner
          components={{
            audio: false,
          }}
          onScan={(code) => {
            const value = code[0]?.rawValue;
            if (value) {
              setToken(value);
              props.onDismissRequest?.();
              submit();
            }
          }}
        />
      </div>
    </Modal>
  );
}
