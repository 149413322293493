import React, { MouseEventHandler, PropsWithChildren } from 'react';

export function TwCard(
  props: PropsWithChildren & {
    className?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
  },
) {
  return (
    <div
      className={`rounded-md bg-white p-6 shadow-md ${props.className ? props.className : ''}`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
