import React, { ReactNode } from 'react';

export function TwInputWithLabel(props: {
  label: ReactNode;
  input: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`flex flex-col gap-1 ${props.className ? props.className : ''}`}
    >
      {props.label}
      {props.input}
    </div>
  );
}
