import { useAuthStore } from '../common/auth/AuthStore';
import { Navigate } from 'react-router';
import { TwButton } from '../common/TwButton';
import touwiLogo from '../assets/images/touwi.svg';
import { useState } from 'react';
import { Modal } from '../common/Modal';
import { CheckNSS } from './nss/CheckNSS';

export default function HomePage() {
  const isAuthenticated = useAuthStore((state) => !!state.token);

  if (!isAuthenticated) {
    return <Navigate to={'/login'} replace={true} />;
  }

  return (
    <div className="relative flex h-svh w-screen items-center justify-center bg-gray-200">
      <div className="m-4 flex w-full max-w-lg flex-col gap-14">
        <TouwiLogo />
        <CheckNSSButton />
      </div>
      <LogoutButton />
    </div>
  );
}

function TouwiLogo() {
  return <img src={touwiLogo} alt="Touwi Logo" />;
}

function LogoutButton() {
  const setToken = useAuthStore((state) => state.setToken);
  return (
    <button
      type="button"
      onClick={() => setToken(undefined)}
      className="absolute end-5 top-5 mx-auto rounded p-2 outline-indigo-600 hover:bg-indigo-600/50 hover:text-white"
    >
      Logout
    </button>
  );
}

function CheckNSSButton() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <TwButton type="button" onClick={() => setShowModal(true)}>
        Check NSS
      </TwButton>
      {showModal && (
        <Modal
          onDismissRequest={() => setShowModal(false)}
          className="flex items-center justify-center"
        >
          <CheckNSS />
        </Modal>
      )}
    </>
  );
}
