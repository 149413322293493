import React, { HTMLInputTypeAttribute, ReactNode } from 'react';
import classNames from 'classnames';

export function TwInput(props: {
  value: string;
  onValue: (value: string) => void;
  inputId?: string;
  type?: HTMLInputTypeAttribute | undefined;
  disabled?: boolean | undefined;
  placeholder?: string | undefined;
  trailing?: ReactNode | undefined;
  autoFocus?: boolean | undefined;
  required?: boolean | undefined;
}) {
  return (
    <div className="relative w-full">
      <input
        className={classNames(
          'w-full rounded border border-gray-500 p-2 focus:border-indigo-600 focus:ring-indigo-300',
          {
            'pe-[44px]': !!props.trailing,
          },
        )}
        id={props.inputId}
        type={props.type}
        value={props.value}
        disabled={props.disabled}
        onChange={(e) => props.onValue(e.target.value)}
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
        autoComplete="off"
        required={props.required}
      />
      {props.trailing && (
        <div className="absolute inset-y-0 end-[10px] my-auto size-[24px] max-h-[24px] max-w-[24px]">
          {props.trailing}
        </div>
      )}
    </div>
  );
}
