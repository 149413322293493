import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface AuthStore {
  token: string | undefined;

  setToken: (token: string | undefined) => void;
}

export const useAuthStore = create<AuthStore>()(
  persist(
    (setState) => ({
      token: undefined,

      setToken: (token: string | undefined) => {
        setState({
          token: token,
        });
      },
    }),
    {
      name: 'auth',
    },
  ),
);
