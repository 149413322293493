import React, { PropsWithChildren } from 'react';

export function TwInputLabel(
  props: PropsWithChildren & {
    htmlFor?: string;
  },
) {
  return (
    <label className="text-sm text-gray-700" htmlFor={props.htmlFor}>
      {props.children}
    </label>
  );
}
