import { create } from 'zustand';
import { useEffect } from 'react';
import { HttpStatusCode, isAxiosError } from 'axios';
import { provisioningApi } from '../../common/services/iot-core-api/Service';
import { DeviceDto, TwinDto } from '../../common/services/iot-core-api/client';

export interface NSSCheckerState {
  deviceId: string;
  setDeviceId: (deviceId: string) => void;

  submit: () => void;
  isSubmitting: boolean;
  error: string | undefined;
  abortController: AbortController | undefined;

  device: DeviceDto | undefined;
  twin: TwinDto | undefined;

  reset: () => void;
}

export const useNSSChecker = create<NSSCheckerState>()(
  (setState, getState) => ({
    deviceId: '',
    setDeviceId: (deviceId: string) => {
      setState({
        deviceId: deviceId,
        error: undefined,
        device: undefined,
        twin: undefined,
      });
    },

    submit: async () => {
      if (getState().isSubmitting) {
        return;
      }

      const deviceId = getState().deviceId.trim();
      if (deviceId.length === 0) {
        return;
      }

      const abortController = new AbortController();
      const isAborted = () => abortController.signal.aborted;
      setState({
        isSubmitting: true,
        abortController: abortController,
      });

      try {
        const [{ data: device }, { data: twin }] = await Promise.all([
          provisioningApi.getDeviceAsProvisioner(deviceId),
          provisioningApi.getTwinAsProvisioner(deviceId),
        ]);
        setState({
          device: device,
          twin: twin,
        });
      } catch (e: unknown) {
        if (!isAborted()) {
          let error = `${e}`;
          if (
            isAxiosError(e) &&
            e.response?.status === HttpStatusCode.NotFound
          ) {
            error = 'Device not found';
          }
          setState({
            error: error,
          });
        }
      } finally {
        if (!isAborted()) {
          setState({
            isSubmitting: false,
            abortController: undefined,
          });
        }
      }
    },
    isSubmitting: false,
    error: undefined,
    abortController: undefined,

    device: undefined,
    twin: undefined,

    reset: () => {
      getState().abortController?.abort();
      setState({
        deviceId: '',
        isSubmitting: false,
        error: undefined,
        abortController: undefined,
        device: undefined,
        twin: undefined,
      });
    },
  }),
);

export function useResetNSSChecker() {
  const reset = useNSSChecker((state) => state.reset);
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);
}
