import React, { PropsWithChildren } from 'react';

export function TwButton(
  props: PropsWithChildren & {
    type?: 'submit' | 'reset' | 'button' | undefined;
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
  },
) {
  return (
    <button
      className={`rounded-md bg-indigo-600 px-4 py-2 text-center text-sm text-white transition-all hover:bg-indigo-500 active:bg-indigo-700 disabled:bg-indigo-600/80 ${props.className ? props.className : ''}`}
      type={props.type}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
