import { PropsWithChildren, useEffect } from 'react';

export function Modal(
  props: PropsWithChildren & {
    className?: string;
    onDismissRequest?: () => void;
  },
) {
  useEffect(() => {
    const abortController = new AbortController();
    addEventListener(
      'keydown',
      (e) => {
        if (e.key === 'Escape') {
          e.preventDefault();
          props.onDismissRequest?.();
        }
      },
      {
        signal: abortController.signal,
      },
    );
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div
      className={`fixed left-0 top-0 z-50 h-screen w-screen bg-black/50 backdrop-blur-sm ${props.className ? props.className : ''}`}
      onClick={props.onDismissRequest}
    >
      {props.children}
    </div>
  );
}
