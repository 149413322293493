import { create } from 'zustand';
import { provisioningLotsApi } from '../common/services/iot-core-api/Service';
import { useAuthStore } from '../common/auth/AuthStore';
import { useEffect } from 'react';

export interface LoginFormState {
  token: string;
  setToken: (token: string) => void;

  abort: AbortController | undefined;

  submit: () => void;
  isSubmitting: boolean;
  submitError: string | undefined;

  reset: () => void;
}

export const useLoginFormState = create<LoginFormState>()(
  (setState, getState) => ({
    token: '',
    setToken: (token) => {
      setState({
        token: token,
        submitError: undefined,
      });
    },

    abort: undefined,

    submit: async () => {
      if (getState().isSubmitting) {
        return;
      }

      const abortController = new AbortController();
      const isAborted = () => abortController.signal.aborted;

      setState({
        abort: abortController,
        isSubmitting: true,
      });

      try {
        const myToken = await provisioningLotsApi.getMyProvisioningLot({
          signal: abortController.signal,
          headers: {
            Authorization: `Bearer ${getState().token}`,
          },
        });
        useAuthStore.getState().setToken(myToken.data.provisioningToken);
      } catch (e: unknown) {
        if (!isAborted()) {
          console.warn('login failed', e);
          setState({ submitError: `Login failed` });
        }
      } finally {
        if (!isAborted()) {
          setState({
            abort: undefined,
            isSubmitting: false,
          });
        }
      }
    },
    isSubmitting: false,
    submitError: undefined,

    reset: () => {
      getState().abort?.abort();
      setState({
        token: '',
        isSubmitting: false,
        submitError: undefined,
        abort: undefined,
      });
    },
  }),
);

export function useLoginFormStateReset() {
  const reset = useLoginFormState((state) => state.reset);
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);
}
