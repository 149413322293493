import { TwCard } from '../../common/TwCard';
import { useNSSChecker, useResetNSSChecker } from './NSSCheckerState';
import { useShallow } from 'zustand/react/shallow';
import { TwInputWithLabel } from '../../common/TwInputWithLabel';
import { TwInputLabel } from '../../common/TwInputLabel';
import { TwInput } from '../../common/TwInput';
import { TwButton } from '../../common/TwButton';
import React, { FormEvent } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import {
  DeviceDto,
  TwinAttributeValueDto,
  TwinDto,
} from '../../common/services/iot-core-api/client';
import classNames from 'classnames';

export function CheckNSS() {
  useResetNSSChecker();
  const [device, twin] = useNSSChecker(
    useShallow((state) => [state.device, state.twin]),
  );

  return (
    <TwCard
      className="m-4 w-[500px] max-w-lg"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {device && twin && <DeviceState device={device} twin={twin} />}
      {!(device && twin) && <InputForm />}
    </TwCard>
  );
}

function InputForm() {
  const submit = useNSSChecker((state) => state.submit);
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    submit();
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={onSubmit}>
      <DeviceIdInput />
      <DeviceQrCodeScanner />
      <SubmitButton />
      <ErrorMessage />
    </form>
  );
}

function DeviceIdInput() {
  const [deviceId, setDeviceId, isSubmitting] = useNSSChecker(
    useShallow((state) => [
      state.deviceId,
      state.setDeviceId,
      state.isSubmitting,
    ]),
  );

  return (
    <TwInputWithLabel
      label={<TwInputLabel htmlFor="device-id">Device id</TwInputLabel>}
      input={
        <TwInput
          inputId="device-id"
          value={deviceId}
          onValue={setDeviceId}
          disabled={isSubmitting}
          required
        />
      }
    />
  );
}

function DeviceQrCodeScanner() {
  const [setDeviceId, submit] = useNSSChecker(
    useShallow((state) => [state.setDeviceId, state.submit]),
  );
  return (
    <Scanner
      components={{
        audio: false,
      }}
      onScan={(result) => {
        const value = result[0]?.rawValue;
        if (value) {
          setDeviceId(value);
          submit();
        }
      }}
    />
  );
}

function SubmitButton() {
  const [deviceId, isSubmitting] = useNSSChecker(
    useShallow((state) => [state.deviceId, state.isSubmitting]),
  );
  return (
    <TwButton
      type="submit"
      disabled={isSubmitting || deviceId.trim().length === 0}
    >
      {isSubmitting ? 'Checking...' : 'Check'}
    </TwButton>
  );
}

function ErrorMessage() {
  const errorMessage = useNSSChecker((state) => state.error);
  if (!errorMessage) {
    return <></>;
  }

  return <div className="w-full text-center text-red-500">{errorMessage}</div>;
}

function DeviceState({ device, twin }: { device: DeviceDto; twin: TwinDto }) {
  return (
    <div className="flex flex-col gap-4">
      <OnlineStatus deviceId={device.deviceId} isOnline={device.isOnline} />
      <NSS nss={twin.reported['nss']} />
      <RefreshButton />
    </div>
  );
}

function OnlineStatus(props: { deviceId: string; isOnline: boolean }) {
  return (
    <div
      className={classNames(
        'rounded p-4 text-white font-bold text-center flex flex-col gap-2 justify-center',
        {
          'bg-green-700': props.isOnline,
          'bg-red-700': !props.isOnline,
        },
      )}
    >
      <div>{props.deviceId}</div>
      <div>{props.isOnline ? 'ONLINE' : 'OFFLINE'}</div>
    </div>
  );
}

function NSS(props: { nss: TwinAttributeValueDto | undefined }) {
  return (
    <div className="flex gap-2">
      <div className="font-bold">NSS:</div>
      {props.nss && !Number.isNaN(props.nss.value) && (
        <div>
          {props.nss.value as number}/10 (updated {whenUpdated(props.nss)})
        </div>
      )}
      {!(props.nss && !Number.isNaN(props.nss.value)) && (
        <div className="text-red-700">unknown</div>
      )}
    </div>
  );
}

function RefreshButton() {
  const [submit, isSubmitting] = useNSSChecker(
    useShallow((state) => [state.submit, state.isSubmitting]),
  );

  return (
    <TwButton type="button" disabled={isSubmitting} onClick={submit}>
      {isSubmitting ? 'Please wait...' : 'Refresh'}
    </TwButton>
  );
}

const relativeTimeFormat = new Intl.RelativeTimeFormat('en', {
  numeric: 'auto',
  style: 'long',
});

function whenUpdated(nss: TwinAttributeValueDto) {
  const updatedAt = new Date(nss.updatedAt);
  const now = new Date();
  const dtMillis = now.getTime() - updatedAt.getTime();

  if (dtMillis < 1000) {
    return 'just now';
  }

  const dtSeconds = dtMillis / 1000;
  const dtSecondsApprox = Math.round(dtSeconds);
  if (dtSecondsApprox < 60) {
    return relativeTimeFormat.format(-dtSecondsApprox, 'seconds');
  }

  const dtMinutes = dtSeconds / 60;
  const dtMinutesApprox = Math.round(dtMinutes);
  if (dtMinutesApprox < 60) {
    return relativeTimeFormat.format(-dtMinutesApprox, 'minutes');
  }

  const dtHours = dtMinutes / 60;
  const dtHoursApprox = Math.round(dtHours);
  if (dtHoursApprox < 24) {
    return relativeTimeFormat.format(-dtHoursApprox, 'hours');
  }

  const dtDays = dtHours / 24;
  const dtDaysApprox = Math.round(dtDays);
  return relativeTimeFormat.format(-dtDaysApprox, 'days');
}
